import { graphql } from "gatsby"
import React, { useState, useCallback } from "react"
import Seo from "../components/seo"
import Layout from "../components/layout"
import TopImage from "../components/topImage"
import Img from 'gatsby-image';
import Lightbox from "react-awesome-lightbox";
import { buildFluidImageData } from '@imgix/gatsby-transform-url';
import "react-awesome-lightbox/build/style.css";

export default function Gallery({ data }) {
  const [isViewerOpen, setIsViewerOpen] = useState(false)
  const [currentImage, setCurrentImage] = useState(0)
  const openImageViewer = useCallback(index => {
    setCurrentImage(index)
    setIsViewerOpen(true)
  }, [])

  const closeImageViewer = () => {
    setCurrentImage(0)
    setIsViewerOpen(false)
  }
  const file = data.markdownRemark
  const media = file.frontmatter.media
  const title = file.frontmatter.title
  const topImage = file.featuredImg.childImageSharp.gatsbyImageData
  let images = []
  media.map((imageUrl, index) => {
    let newImage = {
      url: imageUrl,
      title: `Image ${index}`,
    }
  images.push(newImage);
  })

  return (
    <Layout>
      <Seo title="Gallery" />
      <TopImage topImage={topImage} title={title} />
      <div className="container mx-auto w-9/12 pt-10 pb-10">
        <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
          {media.map((imageUrl, index) => {
            console.log(media)
            const image = buildFluidImageData(
              imageUrl,
              {
                ar: 1, // required
                auto: ["format", "compress"],
              },
              {
                sizes: "90",
              }
            )
            console.log(image)
            return (
              <a onClick={() => openImageViewer(index)}>
                <Img
                  key={index}
                  fluid={image}
                  alt={`Image ${index}`}
                  className="transition ease-in-out delay-150 hover:scale-110 duration-500 rounded-xl"
                />
              </a>
            )
          })}
        </div>
      </div>
      {isViewerOpen && (
        <Lightbox
          images={images}
          onClose={closeImageViewer}
          startIndex={currentImage}
        />
      )}
    </Layout>
  )
}

export const pageQuery = graphql`
  query Images($slug: String) {
    markdownRemark(frontmatter: { path: { eq: $slug } }) {
      id
      frontmatter {
        title
        media
      }
      featuredImg {
        childImageSharp {
          gatsbyImageData(
            transformOptions: { fit: COVER, cropFocus: CENTER }
            placeholder: BLURRED
          )
        }
      }
    }
  }
`
